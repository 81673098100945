import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { glossaryConverter, textbookConverter } from 'web_core/firebase'
import { recentGlossariesRef } from 'web_core/models/glossary'
import { recentTextbooksRef } from 'web_core/models/textbook'
import { deserializeArray } from 'web_core/utils'
import { HomePageProps } from '~/static_props/home'

export const useHomePagesProps = ({
  recentGlossaries: staticRecentGlossaries,
  recentTextbooks: staticRecentTextbooks,
}: HomePageProps) => {
  const [recentGlossaries, loadingRecentGlossaries] = useCollectionDataOnce(
    recentGlossariesRef(10),
    {
      initialValue: deserializeArray(staticRecentGlossaries, {
        ref: glossaryConverter,
      }),
    }
  )
  const [recentTextbooks, loadingRecentTextbooks] = useCollectionDataOnce(
    recentTextbooksRef(10),
    {
      initialValue: deserializeArray(staticRecentTextbooks, {
        ref: textbookConverter,
      }),
    }
  )

  return {
    recentGlossaries,
    recentTextbooks,
    loading: loadingRecentGlossaries || loadingRecentTextbooks,
  }
}
