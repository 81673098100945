import { NextPage } from 'next'
import { Alert } from 'react-bootstrap'
import { CategoryCard } from 'web_core/assets/category'
import { GlossaryList } from 'web_core/assets/glossary'
import { TextbookList } from 'web_core/assets/textbook'
import { useCategories } from 'web_core/hooks'
import { DefaultLayout } from '~/components/Layout/Default'
import { useHomePagesProps } from '~/hooks/useHomePageProps'
import { HomePageProps, getHomePageStaticProps } from '~/static_props/home'

export const getStaticProps = getHomePageStaticProps

const Page: NextPage<HomePageProps> = (props) => {
  const { menuCategories } = useCategories()
  const { recentGlossaries, recentTextbooks } = useHomePagesProps(props)
  return (
    <DefaultLayout
      head={{
        title: '大学数学中心の解説サイト',
      }}
      categoriesMenu={{ pc: true, sp: true, spFixed: true }}
      leftSidebar
      rightSideBar
    >
      <Alert variant="warning" className="py-2">
        現在Mathpediaは移行中です。旧Mathpediaは
        <a href="https://old.math.jp/wiki/メインページ">こちら</a>
        からご覧頂けます。
      </Alert>
      <div className="mathdown-monotone">
        <section>
          <h1 className="mb-3">最近の用語解説</h1>
          {recentGlossaries && <GlossaryList glossaries={recentGlossaries} />}
        </section>
        <section>
          <h1 className="mb-3">最近の参考書</h1>
          {recentTextbooks && <TextbookList textbooks={recentTextbooks} />}
        </section>
        <section>
          <h1 className="mb-3">分野一覧</h1>
          <div className="d-flex flex-column gap-6">
            {menuCategories?.map((category) => (
              <CategoryCard key={category.id} category={category} />
            ))}
          </div>
        </section>
      </div>
    </DefaultLayout>
  )
}

export default Page
